<template>
  <BaseSimilarPreview
    :previews="previews"
    page-name="blog_post"
    slug-name="blogSlug"
  >
    <slot />
  </BaseSimilarPreview>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";
import BaseSimilarPreview from "~/modules/shared/components/articles/preview/BaseSimilarPreview.vue";

const contentfulStore = useContentfulStore();
await contentfulStore.fetchBlog(3);

const previews = contentfulStore.getLimitedBlog(3);
</script>

<style scoped lang="scss"></style>
